import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          This page is for implementing Proof Key for Code Exchange (PKCE) to
          secure your authentication.
        </p>
      </header>
    </div>
  );
}

export default App;
